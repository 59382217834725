import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "header-bar",
  data() {
    return {}
  },
  created() {
    this.fetchTickets();
  },
  watch: {
    ticketStatus() {
      if(this.ticketStatus === 'Success') {
        this.fetchTickets();
      }
    }
  },
  computed: {
    ...mapGetters({
      tickets: `auth/tickets`,
      ticketStatus: `auth/ticketStatus`,
      isAuthenticated: `auth/isAuthenticated`,
    }),
    
    unreadMsg() {
      if(this.isAuthenticated && this.tickets) {
        return this.tickets.reduce((acc, currentTicket) => {
          return acc + currentTicket.unread;
        }, 0)
      } else {
        return 0
      }
      
    }
  },
  methods:{
    ...mapActions({
      logout:`auth/LOGOUT`,
      fetchTickets: `auth/GET_TICKETS`
    }),
    ...mapMutations({

    })
  }
}